<template>
  <section class="uri-synopsis-card">
    <ul class="card-carousel" :class="scrolls" :style="{ 'grid-auto-columns': gridAutoColumns }">
      <li v-for="(card, cardIndex) in block.data" :key="'card-' + cardIndex">
        <figure class="card">
          <div v-if="card.img" class="card-image">
            <router-link :to="card.path">
              <img :src="card.img.src" :srcset="card.img.srcset" :sizes="card.img.sizes" :alt="card.img.alt" :title="card.img.title" :width="card.img.width" :height="card.img.height" />
            </router-link>
          </div>
          <figcaption>
            <div class="card-header">
              <router-link :to="card.path">
                <div class="card-title">{{ card.title }}</div>
              </router-link>
            </div>
            <div v-if="card.subtitle" class="card-subtitle">{{ card.subtitle }}</div>
            <div class="card-body">
              {{ card.synopsis }}
            </div>
          </figcaption>
        </figure>
      </li>
    </ul>
  </section>
</template>

<style>
.uri-synopsis-card {
  overflow: auto;
}
.uri-synopsis-card .card-carousel {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-snap-type: x proximity;
}
.uri-synopsis-card .card-carousel > li {
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
}
.uri-synopsis-card img {
  display: block;
  height: auto;
  width: 100%;
  max-width: 100%;
}
.uri-synopsis-card .card-body {
  position: relative;
}
.uri-synopsis-card .card-header {
  display: flex;
  position: relative;
}
</style>

<script>
// TODO Figure out why mixin breaks this
// import cardSlider from '../mixins/cardSlider';
export default {
  // mixins: [cardSlider],
  name: 'BlockUriSynopsisCard',
  props: {
    block: Object,
  },
  computed: {
    gridAutoColumns() {
      if (this.block.data.length == this.block.properties.cards) {
        return (100 - (parseInt(this.block.data.length) - 1)) / this.block.properties.cards + '%'; // Make Full Width
      } else {
        return (100 - (parseInt(this.block.properties.cards) + 2)) / this.block.properties.cards + '%'; // Show A Little Offset
      }
    },
    scrolls() {
      let scrolls = {};
      scrolls.scroll = this.block.data.length > this.block.properties.cards ? true : false;
      scrolls['no-scroll'] = this.block.data.length == this.block.properties.cards ? true : false;
      scrolls['single'] = this.block.data.length == 1 ? true : false;
      return scrolls;
    },
  },
  data() {
    return {};
  },
};
</script>
